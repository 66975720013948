<template>
  <div class="multi-project-modal">
    <div class="multi-project-modal-wrapper">
      <form @submit.prevent="updateProjects">
        <div class="multi-project-header">
          <h5>Modifier en masse {{ data.length }} projets</h5>
        </div>
        <div class="multi-project-content">
          <!--"Stade" -->
          <b-form-group class="mt-2" label="Stade" label-for="stage-input">
            <Multiselect
              v-model="dataToUpdate.stage"
              @input="handleStageChange"
              id="stage-input"
              label="name"
              track-by="id"
              :showLabels="false"
              :closeOnSelect="true"
              :allow-empty="true"
              :options="currentStages || []"
              :searchable="true"
              :internal-search="true"
              placeholder="-- aucun --"
              required
            >
              <span slot="noResult">Aucun stade trouvée.</span>
              <span slot="noOptions">Aucun stade trouvée.</span>
            </Multiselect>
          </b-form-group>
          <!--"Sous-stade" -->
          <b-form-group
            v-if="dataToUpdate.stage && currentSubStages"
            class="mt-2"
            label="Sous-stade"
            label-for="sub-stage-input"
          >
            <Multiselect
              v-model="dataToUpdate.subStage"
              id="sub-stage-input"
              @input="handleMultiSelectInput"
              label="name"
              track-by="id"
              :showLabels="false"
              :closeOnSelect="true"
              :allow-empty="true"
              :options="currentSubStages || []"
              :searchable="true"
              :internal-search="true"
              placeholder="-- aucun --"
            >
              <span slot="noResult">Aucun sous-stade trouvée.</span>
              <span slot="noOptions">Aucun sous-stade trouvée.</span>
            </Multiselect>
          </b-form-group>
          <div v-if="localError" class="error">
            {{ localError }}
          </div>
          <!-- "Poseur" -->
          <!-- <b-form-group
            class="mt-2"
            label="Poseur"
            label-for="technician-input"
          >
            <Multiselect
              v-model="dataToUpdate.poseur"
              @input="handleMultiSelectInput"
              id="technician-input"
              label="full_name"
              track-by="id"
              :showLabels="false"
              :closeOnSelect="true"
              :allow-empty="true"
              :options="getProjectsUsersPoseurs"
              :searchable="true"
              :internal-search="true"
              :loading="getProjectsUsersPoseursProcessing"
              placeholder="-- aucun --"
            >
              <span slot="noResult">Aucun poseur trouvée.</span>
              <span slot="noOptions">Aucun poseur trouvée.</span>
            </Multiselect>
          </b-form-group> -->
          <!--"Assistant maître d'Ouvrage" -->
          <b-form-group
            class="mt-2"
            label="Assistant maître d'Ouvrage"
            label-for="comm-iti-input"
          >
            <Multiselect
              v-model="dataToUpdate.commercialItinerant"
              @input="handleMultiSelectInput"
              id="comm-iti-input"
              label="full_name"
              track-by="id"
              :showLabels="false"
              :closeOnSelect="true"
              :allow-empty="true"
              :options="getProjectsUsersPrevisiteur || []"
              :searchable="true"
              :internal-search="true"
              :loading="getProjectsUsersPrevisiteurLoading"
              placeholder="-- aucun --"
            >
              <span slot="noResult"
                >Aucun Assistant maître d'Ouvrage trouvée.</span
              >
              <span slot="noOptions"
                >Aucun Assistant maître d'Ouvrage trouvée.</span
              >
            </Multiselect>
          </b-form-group>
          <!--"Commercial sédentaire" -->
          <b-form-group
            class="mt-2"
            label="Commercial Sédentaire"
            label-for="comm-sed-input"
          >
            <Multiselect
              v-model="dataToUpdate.commercialSedetaine"
              @input="handleMultiSelectInput"
              id="comm-sed-input"
              label="full_name"
              track-by="id"
              :showLabels="false"
              :closeOnSelect="true"
              :allow-empty="true"
              :options="getProjectsUsersCommercialSed"
              :searchable="true"
              :internal-search="true"
              :loading="getProjectsUsersCommercialSedProcessing"
              placeholder="-- aucun --"
            >
              <span slot="noResult">Aucun Commercial sédentaire trouvée.</span>
              <span slot="noOptions">Aucun Commercial sédentaire trouvée.</span>
            </Multiselect>
          </b-form-group>
          <!--"Responsable planning" -->
          <!-- <b-form-group
            class="mt-2"
            label="Responsable planning"
            label-for="quality-manager-input"
          >
            <Multiselect
              v-model="dataToUpdate.qualityManager"
              @input="handleMultiSelectInput"
              id="quality-manager-input"
              label="full_name"
              track-by="id"
              :showLabels="false"
              :closeOnSelect="true"
              :allow-empty="true"
              :options="getProjectsUsersRespPlanning"
              :searchable="true"
              :internal-search="true"
              :loading="getProjectsUsersRespPlanningLoading"
              placeholder="-- aucun --"
            >
              <span slot="noResult">Aucun responsable planning trouvée.</span>
              <span slot="noOptions">Aucun responsable planning trouvée.</span>
            </Multiselect>
          </b-form-group> -->
          <!--"Commercial" -->
          <!-- <b-form-group
            class="mt-2"
            label-for="commercial-input"
            v-if="currentUser && currentUser.isSuper"
            label="Commercial"
          >
            <Multiselect
              id="commercial-input"
              v-model="dataToUpdate.commercialRegie"
              @input="handleMultiSelectInput"
              label="full_name"
              track-by="id"
              :showLabels="false"
              :closeOnSelect="true"
              :allow-empty="true"
              :options="getProjectsUsersCommercial || []"
              :searchable="true"
              :internal-search="true"
              :loading="getProjectsUsersCommercialProcessing"
              placeholder="-- aucun --"
            >
              <span slot="noResult">Aucun Commercial trouvé.</span>
              <span slot="noOptions">Aucun Commercial trouvé.</span>
            </Multiselect>
          </b-form-group> -->
          <!--"Source" -->
          <b-form-group class="mt-2" label-for="source-input" label="Source">
            <Multiselect
              id="source-input"
              v-model="dataToUpdate.source"
              @input="handleMultiSelectInput"
              label="name"
              track-by="id"
              :showLabels="false"
              :closeOnSelect="true"
              :allow-empty="true"
              :options="getProjectsUsersSources || []"
              :searchable="true"
              :internal-search="true"
              :loading="getProjectsUsersSourcesProcessing"
              placeholder="-- aucun --"
            >
              <span slot="noResult">Aucun source trouvé.</span>
              <span slot="noOptions">Aucun source trouvé.</span>
            </Multiselect>
          </b-form-group>
          <!--"Ingénieur" -->
          <b-form-group
            class="mt-2"
            label-for="Ingénieur-input"
            label="Ingénieur"
          >
            <Multiselect
              id="Ingénieur-input"
              v-model="dataToUpdate.ingenieurAudit"
              @input="handleMultiSelectInput"
              label="full_name"
              track-by="id"
              :showLabels="false"
              :closeOnSelect="true"
              :allow-empty="true"
              :options="getProjectsUsersIngenieur || []"
              :searchable="true"
              :internal-search="true"
              :loading="getProjectsUsersIngenieurProcessing"
              placeholder="-- aucun --"
            >
              <span slot="noResult">Aucun Ingénieur trouvé.</span>
              <span slot="noOptions">Aucun Ingénieur trouvé.</span>
            </Multiselect>
          </b-form-group>
          <div
            class="delete-Multi-project"
            v-if="currentUser && currentUser.isSuper"
          >
            <a href="#" @click.prevent="handleDeleteMultiProject">
              <font-awesome-icon class="mr-1" icon="trash" />
              Supprimer les {{ data.length }} projets
            </a>
          </div>
        </div>
        <!-- Delete MultiProject Modal -->
        <b-modal
          no-close-on-backdrop
          ref="delete-Multi-project"
          id="delete-Multi-project"
          :title="'Supprimer les ' + data.length + ' projets'"
          :hide-footer="true"
        >
          <div>
            Êtes-vous certain de vouloir supprimer les
            <strong>
              {{ data.length ? data.length : '' }}
            </strong>
            projets ?
          </div>
          <ul>
            <li
              v-for="projets in data"
              :key="projets.id"
              class="titles-projets"
            >
              {{ projets.contact_full_name }}
            </li>
          </ul>
          <div class="danger-message mt-2 mb-2">
            <font-awesome-icon class="mr-1" icon="exclamation-circle" /> La
            suppression sera définitive !
          </div>
          <div class="message">
            <div v-if="getProjectsProcessing" class="three-dots-loading">
              Chargement en cours
            </div>
            <div v-if="getProjectsError" class="error">
              <ul v-if="Array.isArray(getProjectsError)">
                <li v-for="(e, index) in getProjectsError" :key="index">
                  {{ e }}
                </li>
              </ul>
              <span v-else>{{ getProjectsError }}</span>
            </div>
          </div>
          <div class="form-actions">
            <b-button
              @click="hideModal('delete-Multi-project')"
              variant="outline-secondary"
            >
              Annuler
            </b-button>
            <b-button @click="doDeleteMultiProject" variant="success">
              Valider
            </b-button>
          </div>
        </b-modal>
        <!-- END Delete MultiProject Modal -->
        <div class="multi-project-footer">
          <div v-if="localError2" class="error message mt-1 ml-2">
            {{ localError2 }}
          </div>
          <div
            v-if="getProjectsProcessing"
            class="three-dots-loading message mt-1 ml-2"
          >
            Chargement en cours
          </div>
          <div v-if="updateSuccess" class="success  message mt-1 ml-2">
            {{ data.length }}
            projets ont été modifié!
          </div>
          <div class="form-actions">
            <b-button
              @click="handleClose"
              variant="outline-secondary"
              :disabled="getProjectsProcessing"
            >
              Annuler
            </b-button>
            <b-button
              variant="success"
              type="submit"
              :disabled="getProjectsProcessing"
            >
              Enregistrer
            </b-button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Multiselect from 'vue-multiselect'

export default {
  name: 'multi-project-modal',
  props: {
    data: {
      required: true
    },
    type: {
      required: true
    }
  },
  components: {
    Multiselect
  },
  data() {
    return {
      dataToUpdate: {
        stage: null,
        subStage: null,
        poseur: null,
        commercialItinerant: null,
        commercialSedetaine: null,
        qualityManager: null,
        source: null,
        commercialRegie: null,
        ingenieurAudit: null
      },
      localError: null,
      localError2: null,
      updateSuccess: null
    }
  },
  methods: {
    ...mapActions([
      'updateProjectsMultiple',
      'fetchUsersRespPlanning',
      'deleteProjectsMultiple',
      'fetchProjectPoseur',
      'fetchProjectPrevisiteur',
      'fetchProjectCommSed',
      'fetchProjectSources',
      'fetchProjectCommercial',
      'fetchProjectIngenieur'
    ]),
    handleClose() {
      this.$emit('close')
    },
    async updateProjects() {
      if (this.dataToUpdate.stage && !this.dataToUpdate.subStage) {
        this.localError = 'Le sous-stade est obligatoire'
        return
      }
      // !this.dataToUpdate.qualityManager &&
      //         !this.dataToUpdate.poseur &&
      // !this.dataToUpdate.commercialRegie &&

      if (
        !this.dataToUpdate.stage &&
        !this.dataToUpdate.commercialItinerant &&
        !this.dataToUpdate.commercialSedetaine &&
        !this.dataToUpdate.source &&
        !this.dataToUpdate.ingenieurAudit
      ) {
        this.localError2 = 'Aucun changement à faire'
        return
      }
      const data = {
        projets: this.data.map(item => item.id)
      }
      if (this.dataToUpdate.stage) {
        data.cstage_id = this.dataToUpdate.stage.id
      }
      if (this.dataToUpdate.subStage) {
        data.csubstage_id = this.dataToUpdate.subStage.id
      }
      // if (this.dataToUpdate.poseur) {
      //   data.technician = this.dataToUpdate.poseur.id
      // }
      if (this.dataToUpdate.commercialItinerant) {
        data.commercial_itinerant = this.dataToUpdate.commercialItinerant.id
      }
      if (this.dataToUpdate.commercialSedetaine) {
        data.commercial_sedentaire = this.dataToUpdate.commercialSedetaine.id
      }
      // if (this.dataToUpdate.qualityManager) {
      //   data.quality_manager = this.dataToUpdate.qualityManager.id
      // }
      if (this.dataToUpdate.source) {
        data.source_id = this.dataToUpdate.source.id
      }
      // if (this.dataToUpdate.commercialRegie) {
      //   data.commercial_regie_id = this.dataToUpdate.commercialRegie.id
      // }
      if (this.dataToUpdate.ingenieurAudit) {
        data.ingenieur_audit = this.dataToUpdate.ingenieurAudit.id
      }

      const response = await this.updateProjectsMultiple({
        data,
        projects: this.data
      })
      if (response) {
        this.updateSuccess = true
        this.resetData()
        setTimeout(() => {
          this.$emit('close')
        }, 1500)
      }
    },
    handleStageChange() {
      this.dataToUpdate.subStage = null
      this.resetMessages()
    },
    handleMultiSelectInput() {
      this.resetMessages()
    },
    resetMessages() {
      this.localError = null
      this.localError2 = null
      this.updateSuccess = null
    },
    resetData() {
      this.dataToUpdate = {
        stage: null,
        subStage: null,
        poseur: null,
        commercialItinerant: null,
        commercialSedetaine: null,
        qualityManager: null,
        source: null,
        commercialRegie: null,
        ingenieurAudit: null
      }
    },
    handleDeleteMultiProject() {
      this.$refs['delete-Multi-project'].show()
    },
    hideModal(ref) {
      if (ref && this.$refs[ref]) {
        this.$refs[ref].hide()
      }
    },
    async doDeleteMultiProject() {
      const response = await this.deleteProjectsMultiple({
        projects: this.data
      })
      if (response) {
        this.hideModal('delete-Multi-project')
      }
    }
  },
  computed: {
    ...mapGetters([
      'getProjectsProcessing',
      'getProjectsError',
      'getPipelinesPipelines',
      'getProjectsUsersPoseurs',
      'getProjectsUsersPoseursProcessing',
      'getProjectsUsersPrevisiteur',
      'getProjectsUsersPrevisiteurLoading',
      'getProjectsUsersRespPlanning',
      'getProjectsUsersRespPlanningLoading',
      'getProjectsUsersCommercialSed',
      'getProjectsUsersCommercialSedProcessing',
      'getProjectsUsersSources',
      'getProjectsUsersSourcesProcessing',
      'getProjectsUsersCommercial',
      'getProjectsUsersCommercialProcessing',
      'currentUser',
      'getProjectsUsersIngenieur',
      'getProjectsUsersIngenieurProcessing'
    ]),
    currentStages: function() {
      for (let x = 0; x < this.getPipelinesPipelines.length; x++) {
        if (
          this.getPipelinesPipelines[x].type.id == this.type.id &&
          this.getPipelinesPipelines[x].is_principal == 1
        ) {
          return this.getPipelinesPipelines[x].stages
        }
      }
      return null
    },
    currentSubStages: function() {
      if (!this.dataToUpdate.stage) {
        return null
      }
      if (
        this.dataToUpdate.stage.subStages &&
        this.dataToUpdate.stage.subStages.length
      ) {
        return this.dataToUpdate.stage.subStages
      }
      return null
    }
  },
  mounted() {
    // Resp.planning
    // if (
    //   (!this.getProjectsUsersRespPlanning ||
    //     !this.getProjectsUsersRespPlanning.length) &&
    //   !this.getProjectsUsersRespPlanningLoading
    // ) {
    //   this.fetchUsersRespPlanning()
    // }
    // Commercial
    // if (
    //   (!this.getProjectsUsersCommercial ||
    //     !this.getProjectsUsersCommercial.length) &&
    //   !this.getProjectsUsersCommercialProcessing
    // ) {
    //   this.fetchProjectCommercial()
    // }
    // Sources
    if (
      (!this.getProjectsUsersSources || !this.getProjectsUsersSources.length) &&
      !this.getProjectsUsersSourcesProcessing
    ) {
      this.fetchProjectSources()
    }
    // Ingenieur
    if (
      (!this.getProjectsUsersIngenieur ||
        !this.getProjectsUsersIngenieur.length) &&
      !this.getProjectsUsersIngenieurProcessing
    ) {
      this.fetchProjectIngenieur()
    }
    //  Commercial sedentaire
    if (
      (!this.getProjectsUsersCommercialSed ||
        !this.getProjectsUsersCommercialSed.length) &&
      !this.getProjectsUsersCommercialSedProcessing
    ) {
      this.fetchProjectCommSed()
    }
    // Previsiterur / Assistant maître d'Ouvrage
    if (
      (!this.getProjectsUsersPrevisiteur ||
        !this.getProjectsUsersPrevisiteur.length) &&
      !this.getProjectsUsersPrevisiteurLoading
    ) {
      this.fetchProjectPrevisiteur()
    }
    // Poseur
    // if (
    //   (!this.getProjectsUsersPoseurs || !this.getProjectsUsersPoseurs.length) &&
    //   !this.getProjectsUsersPoseursProcessing
    // ) {
    //   this.fetchProjectPoseur()
    // }
  }
}
</script>

<style lang="scss" scoped>
.multi-project-modal {
  position: fixed;
  height: calc(100% - 48px);
  width: 500px;
  top: 48px;
  right: 0;
  z-index: 101;

  .multi-project-modal-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #fff;
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.32);
    font-size: 13px;
    color: #5e5e5e;
    form {
      height: 100%;
      .multi-project-header {
        padding: 30px 30px 8px 30px;
      }
      .multi-project-content {
        padding: 0px 30px;
        height: calc(100% - 147px);
        overflow: auto;
        .delete-Multi-project {
          padding: 10px;
          a {
            color: #e73e01;
          }
        }
      }
      .multi-project-footer {
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
        padding: 30px;
        .message {
          position: absolute;
          left: 30px;
          top: 36px;
        }
      }
    }
  }
  .error {
    color: #ff6961;
  }
  .success {
    color: #1e7e34;
  }
}
#delete-Multi-project {
  .danger-message {
    color: #e73e01;
  }
  .titles-projets {
    margin-top: 5px;
    margin-bottom: 0;
  }
}
</style>
